#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center 0;
    background-size: cover;
    padding-bottom: 4rem;
}

#header .header1 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

#header .header1 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 10vw;
    margin-top: 7vh;
}

#header .header1 .container .left .top {
    text-align: left;
}

#header .header1 .container .left .top img {
    width: 40%;
}

#header .header1 .container .left .title {
    color: #0075FF;
    text-align: left;
    font-size: 61px;
    line-height: 50px;
}

#header .header1 .container .left .title span {
    color: #022974;
    font-size: 37px;
    line-height: 40px;
    font-weight: 400;
}

#header .header1 .container .left .mobile {
    display: none;
}

#header .header1 .container .left #ctaWpp {
    width: 70%;
}

#header .header1 .container .right {
    margin-top: 10vh;
}

#header .header1 .container .right img {
    width: 80%;
    padding-right: 4rem;
}

#header .header2 .container .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 1rem;
}

#header .header2 .container .gallery .item {
    background-color: #F7F9FD;
    border-radius: 7px;
    height: 100%;
}

#header .header2 .container .gallery .item .bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

#header .header2 .container .gallery .item .bottom .item_title {
    color: #0075FF;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}

#header .header2 .container .gallery .item .bottom .item_text {
    text-align: center;
}

#header .header2 .container .gallery .item .bottom #ctaWpp {
    margin: auto auto 0;
    width: 95%;
}

#header .header2 .container .gallery .item.item1 .bottom #ctaWpp {
    margin-top: 1.7rem;
}

#header .header3 {
    background: url("../../assets/header3_bg.png") no-repeat center center;
    background-size: cover;
}

#header .header3 .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

#header .header3 .container .left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .left .title {
    text-align: left;
    color: white;
}

#header .header3 .container .left .mobile {
    display: none;
}

#header .header3 .container .left .text {
    color: white;
}

#header .header3 .container .left #ctaWpp {
    width: 60%;
}

#header .header3 .container .right img {
    width: 80%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center 0;
        background-size: cover;
    }

    #header .header1 .container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #header .header1 .container .left {
        margin: 5vh 2vw 0;
    }

    #header .header1 .container .left .top img {
        width: 50%;
    }

    #header .header1 .container .left .title {
        text-align: center;
        font-size: 50px;
    }

    #header .header1 .container .left .title span {
        font-size: 29px;
    }

    #header .header1 .container .left .mobile {
        display: block;
    }

    #header .header1 .container .left .mobile img {
        width: 60%;
    }

    #header .header1 .container .left .text {
        text-align: center;
    }

    #header .header1 .container .left #ctaWpp {
        width: 60%;
        margin: 0 auto;
    }

    #header .header1 .container .right {
        display: none;
    }

    #header .header2 .container .gallery {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    #header .header2 .container .gallery .item {
        width: 80%;
        margin: 0 auto;
    }

    #header .header2 .container .gallery .item .bottom .item_title {
        font-size: 22px;
    }

    #header .header2 .container .gallery .item .bottom #ctaWpp {
        width: 70%;
    }

    #header .header2 .container .gallery .item.item1 .bottom #ctaWpp {
        margin-top: 0;
    }

    #header .header3 .container {
        grid-template-columns: 1fr;
    }

    #header .header3 .container .left .title {
        text-align: center;
    }

    #header .header3 .container .left .mobile {
        display: block;
    }

    #header .header3 .container .left .mobile img {
        width: 70%;
    }

    #header .header3 .container .left .text {
        text-align: center;
    }

    #header .header3 .container .left #ctaWpp {
        width: 60%;
        margin: 0 auto;
    }

    #header .header3 .container .right {
        display: none;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .left {
        margin: 4vh 1vw 0;
    }

    #header .header1 .container .left .mobile_top {
        display: flex;
        justify-content: space-between;
    }

    #header .header1 .container .left .mobile_top .logo {
        text-align: left;
    }

    #header .header1 .container .left .mobile_top .logo img {
        width: 80%;
    }

    #header .header1 .container .left .mobile_top .frete {
        text-align: right;
    }

    #header .header1 .container .left .mobile_top .frete img {
        width: 80%;
    }

    #header .header1 .container .left .title {
        font-size: 40px;
        line-height: 30px;
        margin-top: 1rem;
    }

    #header .header1 .container .left .title span {
        font-size: 20px;
        line-height: 20px;
    }

    #header .header1 .container .left .mobile img {
        width: 90%;
    }

    #header .header1 .container .left #ctaWpp {
        width: 80%;
    }

    #header .header2 .container .gallery .item {
        width: 100%;
    }

    #header .header2 .container .gallery .item .bottom .item_title {
        font-size: 20px;
    }

    #header .header2 .container .gallery .item .bottom #ctaWpp {
        width: 90%;
    }

    #header .header3 .container .left .mobile img {
        width: 90%;
    }

    #header .header3 .container .left #ctaWpp {
        width: 80%;
    }
}