#about .about1 .container .text {
  text-align: center;
  max-width: 672px;
  margin: -1rem auto 0;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

#about .about1 .container .gallery .item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#about .about1 .container .gallery .item .top img {
  width: 40%;
}

#about .about1 .container .gallery .item .item_title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #0075FF;
}

#about .about1 .container .gallery .item .item_text {
  text-align: center;
}

#about .about2 {
  background-color: #F2F7FF;
}

#about .about2 .container .text {
  color: #0075FF;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

#about .about2 .container #ctaWpp {
  margin: 0 auto;
  width: 25%;
}

#about .about2 .container #ctaWpp a figure img {
  width: 70%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .gallery {
    grid-template-columns: 1fr;
  }

  #about .about1 .container .gallery .item .top img {
    width: 15%;
  }

  #about .about1 .container .gallery .item .item_title {
    font-size: 25px;
  }

  #about .about2 .container .text {
    font-size: 25px;
  }

  #about .about2 .container #ctaWpp {
    width: 50%;
  }

  #about .about2 .container #ctaWpp a figure img {
    width: 55%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .gallery .item .top img {
    width: 20%;
  }

  #about .about1 .container .gallery .item .item_title {
    font-size: 20px;
  }

  #about .about2 .container .text {
    font-size: 20px;
  }

  #about .about2 .container #ctaWpp {
    width: 60%;
  }

  #about .about2 .container #ctaWpp a figure img {
    width: 65%;
  }
}