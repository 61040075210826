#ctaWpp {
    background: #09CE58;
    font-weight: 700;
    padding: .6rem .5rem;
    width: 40%;
    height: fit-content;
    border-radius: 10rem;
}

#ctaWpp a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;

}

#ctaWpp a figure {
    width: 12%;
    margin-top: .2rem;
}

#ctaWpp a figure img {
    width: 50%;
}

#ctaWpp p {
    font-size: 21px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: white;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 60%;
    }

    #ctaWpp p {
        font-size: 18px;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp {
        width: 75%;
    }

    #ctaWpp p {
        font-size: 16px;
    }
}